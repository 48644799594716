// MetaTags.tsx
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation, useSearchParams} from "react-router-dom";
import useGA from "../../common/useGA4";

interface MetaTagsProps {
    description?: string;
    shareUrl?: string;
}

const UI_STRINGS = require('../../common/ui-strings.json')

const MetaTags: React.FC<MetaTagsProps> = ({description, shareUrl}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let queryParamSearchStrString = searchParams.get("search") || '';
    let queryParamSearchStr = decodeURIComponent(queryParamSearchStrString);
    const {sendGAPageView} = useGA();

    const [locationKey, setLocationKey] = useState<string>('');
    const location = useLocation();
    const [docTitle, setDocTitle] = useState<string>(UI_STRINGS.metaTags.titles.default);

    useEffect(() => {
        let locationSecondLevel = locationKey ? locationKey : location.pathname.split('/')[1];
        if (queryParamSearchStr) {
            setDocTitle(UI_STRINGS.metaTags.titles['searchResults'] + ': ' + queryParamSearchStr)
        } else if (locationSecondLevel) {
            let str1 = (UI_STRINGS.metaTags.titles[locationSecondLevel])
            let str2 = ''
            if (location.state && location.state.originalSearch && location.state.originalSearch.originalSearchString) {
                str2 = location.state.originalSearch.originalSearchString
            }

            setDocTitle(str1 + ' \'' + str2 + '\'')

        }
    }, [location]);

    useEffect(() => {
        let page = window.location.pathname.split('/')[1];
        sendGAPageView(page, docTitle);
    }, [docTitle])

    return (
        <Helmet>
            <title>{docTitle}</title>
            <meta property="og:title"
                  content={docTitle}/>
            {description && (
                <>
                    <meta property="og:description" content={description}/>
                </>
            )}
            {shareUrl && (
                <>
                    <meta property="og:url" content={shareUrl}/>
                </>
            )}
        </Helmet>
    );
};

export default MetaTags;