import {useQuery} from "react-query";
import QuestionType from "../types/question.type";
import QuestionDataService from "../services/question.service";
import {useUser} from "../context/UserProvider/UserProvider";
import UiConfig from "../common/ui-config";

const EXAMPLES_NUM = 10;

export function useExamples(org_id: string, handleSearchExamples: (res: any) => void, handleErrors: any) {
    const {accountParams} = useUser();
    const {isFetching: isLoadingExamples, refetch: getRandomExamples} = useQuery<QuestionType[], Error>(
        "query-random-questions",
        async () => {
            return await QuestionDataService.getRandomQuestions(org_id, EXAMPLES_NUM, accountParams);
        },
        {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleSearchExamples(res),
            onError: (err: any) => {
                console.log("ERROR")
                handleErrors('allDown', err);
            },
        }
    );
    return {isLoadingExamples, getRandomExamples};
}
