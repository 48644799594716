import apiClient from "../common/api-client";


const getAllStudies = async (org_id: string) => {
    const response = await apiClient.get<any>(`/studies?org_id=${org_id}`);
    return response.data;
}

const getStudyById = async (org_id: string, study_id: string | undefined) => {
    if (study_id === undefined) return Error
    const response = await apiClient.get<any>(`/studies/${study_id}?org_id=${org_id}`);
    return response.data;

}
const getStudiesByIds = async (org_id: string, study_ids: string[] | undefined) => {
    if (study_ids === undefined) return Error
    let body = {"study_ids": study_ids};
    const response = await apiClient.post<any>(`/studies_list?org_id=${org_id}`, body);
    return response.data;

}

const StudyDataService = {
    getStudyById, getAllStudies, getStudiesByIds
}

export default StudyDataService;
