import {useQuery} from "react-query";
import QuestionType from "../types/question.type";
import QuestionDataService from "../services/question.service";
import {useUser} from "../context/UserProvider/UserProvider";
import UiConfig from "../common/ui-config";

const EXAMPLES_NUM = 10;

export function useMostCorrelated(org_id: string, handleMostCorrelatedResults: (res: any) => void, handleError: any, baseQuestion: QuestionType | null, custom_text: string) {
    const {accountParams} = useUser();
    const {
        isFetching: isLoadingMostCorrelated,
        refetch: requestMostCorrelated
    } = useQuery<number[], Error>(
        "query-request-most-correlated",
        async () => {
            if (baseQuestion && baseQuestion.universal_q_id !== null) {
                return await QuestionDataService.getMostCorrelated(org_id, baseQuestion.universal_q_id, accountParams);
            } else {
                return await QuestionDataService.getMostCorrelatedForText(org_id, custom_text, accountParams);
            }
        },
        {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleMostCorrelatedResults(res),
            onError: (err: any) => handleError(err, null),
        }
    );

    return {isLoadingMostCorrelated, requestMostCorrelated};
}
