import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import MetaTags from "./MetaTags";
import {useUser} from "../../context/UserProvider/UserProvider";
import AuthPanel from "./authPanel";
import ContactUsButton from "./contact-us-button.component";
import AuthModal from "./authM.component";

const logo = require('../../assets/logo.png');

type Props = {}

const TopNav = (props: Props) => {
    let navigate = useNavigate();
    const {authModalShow} = useUser();
    const goHome = () => {
        let path = `/`;
        navigate(path);
    }
    const [authKey, setAuthKey] = useState<number>(0);
    useEffect(() => {
        if (authModalShow) {
            // Generate a new unique key whenever authModalShow changes
            setAuthKey(Date.now());
        }
    }, [authModalShow]);

    return (
        <div className="main_top p-3 hoverPointer d-flex justify-content-between">
            <MetaTags/>
            <div className="d-flex justify-content-start align-items-start align-items-lg-center">
                <img src={logo} alt="" style={{
                    maxHeight: 'fit-content',
                    maxWidth: '70px',
                    height: 'auto',
                    width: 'auto'
                }}
                     className={window.innerWidth > 998 ? 'img-fluid img-fluid-large' : 'img-fluid'}
                     onClick={goHome}/>
                <div onClick={goHome} style={{
                    lineHeight: '1.1',
                    paddingRight: '5rem',
                    paddingLeft: '0.5rem',
                    color: 'hsl(190, 100%, 20%)',
                    maxWidth: '40vw',
                    width: '35rem',
                    // fontStyle: 'italic',
                }}
                     className='lead d-none d-lg-flex'>
                    Explore 1 million human correlations spanning personality, demographics, behaviors, and
                    beliefs
                </div>
            </div>

            <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                <AuthPanel/>
                <ContactUsButton/>
            </div>


            {authModalShow && <AuthModal key={authKey}/>}

        </div>
    )


}
export default TopNav;