// hooks/useGA.ts
import {useEffect} from 'react';
import ReactGA from 'react-ga4';
import AppSettings from '../common/app-settings';

const useGA = () => {
    useEffect(() => {
        const debugHostnames = ['localhost', '127.0.0.1', '0.0.0.0', 'dev.personalitymap'];
        ReactGA.initialize(AppSettings.GAId);
        ReactGA.set({debug: debugHostnames.some(hostname => window.location.hostname.includes(hostname))});
    }, []);

    const sendGAPageView = (page: string, title: string) => {
        ReactGA.send({hitType: 'pageview', page, title});
    };

    const sendGAEvent = (category: string, action: string, label?: string, nonInteraction?: boolean) => {
        ReactGA.send({
            hitType: 'event',
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            nonInteraction,
        });
    };

    const setGAUserId = (userId: string | null) => {
        ReactGA.set({user_id: userId});
    };

    return {
        sendGAPageView,
        sendGAEvent,
        setGAUserId,
    };
};

export default useGA;