import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import {BrowserRouter} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import {Authenticator} from '@aws-amplify/ui-react';
import App from './App';
import {QueryClient, QueryClientProvider} from "react-query";

// Original console methods
const originalWarn = console.warn;
const originalErr = console.error;

// Function to check if the message should be suppressed
const suppressDuplicatesMessage = (message: string): boolean => {
    // Generic pattern, focusing on invariant parts
    const duplicateKeyPattern = /Encountered two children with the same key,/;
    const duplicateKeyPattern2 = /Setting a `style` bypass at element creation should be done only when absolutely necessary/;
    return duplicateKeyPattern.test(message) || duplicateKeyPattern2.test(message);
};

const shouldSuppressMessage = (args: any[]): boolean => {
    return args.some(arg =>
        typeof arg === 'string' && suppressDuplicatesMessage(arg)
    );
};

// Overriding console.warn
console.warn = function (...args) {
    // console.log('[DEBUG] console.warn called with args:', args);
    if (shouldSuppressMessage(args)) {
        // console.log('[DEBUG] Suppressed duplicate key warning:', args);
        return;
    }
    originalWarn.apply(console, args);
};

// Overriding console.error
console.error = function (...args) {
    // console.log('[DEBUG] console.error called with args:', args);
    if (shouldSuppressMessage(args)) {
        // console.log('[DEBUG] Suppressed duplicate key error:', args);
        return;
    }
    originalErr.apply(console, args);
};

// Set up the Query Client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: true,
            staleTime: 5 * 60 * 1000, // 5 minutes
        },
    },
});

// Render the React application
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <Authenticator.Provider>
                <App/>
            </Authenticator.Provider>
        </QueryClientProvider>
    </BrowserRouter>
);