// todo: automate this!
// todo: IMPORTANT! Update warmer on the EventBridge before pointing to a new API deployment!
const useDevBackEnd = true; //url in api-client.ts
const maintenanceMode = false;
const whiteListStaticUrls = ['terms_of_service', 'privacy_policy', 'about']
const GAId = "G-7LJCX9FNGM";

const AppSettings = {
    useDevBackEnd,
    maintenanceMode,
    whiteListStaticUrls, GAId,
}

export default AppSettings;
