import {useQuery} from 'react-query';
import QuestionDataService from '../services/question.service';
import QuestionType from "../types/question.type";
import useGA from "../common/useGA4";
import {useUser} from "../context/UserProvider/UserProvider";


export function useSearch(searchString: string, org_id: string, debugMode: boolean, setGetSearchResult: any, handleErrors: any, handleSearchResult: any) {
    const {sendGAEvent} = useGA();
    const {accountParams} = useUser();
    const {isFetching: isLoadingSearchResults, refetch: goDoTheSearch} = useQuery<QuestionType[], Error>(
        "query-search-questions",
        async () => {
            const check: boolean = (searchString).trim().length > 0;
            console.log("CHECK: ", check);
            if (check) {
                sendGAEvent("Question Search Page",
                    "Search request sent to API",
                    searchString,
                    true
                );
                return await QuestionDataService.searchQuestions(org_id, searchString, debugMode, accountParams);
            }
        },
        {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleSearchResult(res),
            onError: (err: any) => {
                setGetSearchResult(null);
                handleErrors('allDown', err);
            },
        }
    );

    return {
        isLoadingSearchResults,
        goDoTheSearch,
    }
}