import {useQuery} from "react-query";
import QuestionType from "../types/question.type";
import QuestionDataService from "../services/question.service";
import {useUser} from "../context/UserProvider/UserProvider";
import QuestionStatisticsType from "../types/question-statistics.type";
import StudyQuestionType from "../types/study-question.type";

export function useQuestionData(
    org_id: string,
    id: string,
    handleGetQuestionResult: (res: any) => void,
    handleErrors: any,
    baseQuestion: any,
    custom_text: any,
    handleStatisticsResult: (res: any) => void,
    displayStudyQsResult: (res: any) => void,
) {
    const {accountParams} = useUser();
    const {isFetching: isLoadingQuestion, refetch: getQuestion} = useQuery<QuestionType, Error>(
        "query-single-question",
        async () => {
            return await QuestionDataService.getQuestionById(org_id, id, accountParams);
        }, {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleGetQuestionResult(res),
            onError: (err: any) =>
                handleErrors(err, null),
        }
    )
    const {isFetching: isLoadingQStatistics, refetch: getQuestionStatistics} = useQuery<QuestionStatisticsType, Error>(
        "query-question-statistics",
        async () => {
            if (baseQuestion?.universal_q_id) {
                return await QuestionDataService.getQStatisticsPredictionById(org_id, id);

            }
            if (!baseQuestion?.universal_q_id && custom_text) {
                return await QuestionDataService.getStatisticsPredictionForText(org_id, custom_text);
            }
        }, {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleStatisticsResult(res),
            onError: (err: any) => handleErrors(err, null),

        }
    )
    
    const {
        isFetching: isLoadingQStudyQuestions,
        refetch: getQStudyQuestions
    } = useQuery<StudyQuestionType[], Error>(
        "query-question-study-questions",
        async () => {
            if (!custom_text) {
                return await QuestionDataService.getQStudyQuestions(org_id, id);
            }
        }, {
            enabled: false,
            retry: 2,
            onSuccess: (res) => displayStudyQsResult(res),
            onError: (err: any) => handleErrors(err, null),

        }
    )

    return {
        isLoadingQuestion,
        getQuestion,
        isLoadingQStatistics,
        getQuestionStatistics,
        isLoadingQStudyQuestions,
        getQStudyQuestions
    };
}
