import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import QuestionType from "../types/question.type";
import {withRouter} from "../common/with-router";
import QuestionDataService from "../services/question.service";
import {useQuery} from "react-query";
import {Tab, Tabs} from 'react-bootstrap';
import QuestionStatisticsType from "../types/question-statistics.type";
import standardizeTexts from "../common/standardize_texts";
import StudyQuestionType from "../types/study-question.type";
import Utils from "../common/utils";
import UiConfig from "../common/ui-config";
import StatsChart from "./statisticsTab/statsChart";
import StatsTable from "./statisticsTab/statsTable";
import {LoadingGif, LoadingSimple, Surprise} from "./UIElements";
import MostCorrelated from "./correlationsComponents/most-correlated.component";
import ReactGA from "react-ga4";
import NetworkComponent from "./cyDiagram/Network.component";
import FactorsChart from "./otherQuestionTabs/factorsChart";
import FactorsDataType from "../types/factorsDataType";
import ShareBlockOffCanvasComponent from "./shareComponent/shareBlockOffCanvasComponent";
import StudyInfoComponent from "./stydyDetails/studyInfo.component";
import {useStudyDetails, useLinearRegression, useCorrelation} from "../requestHooks";
import RelatedArticles from "./statisticsTab/relatedArticles.component";
import InputBlockComponent from "./inputBlock/inputBlock.component";
import {useConnections} from "../requestHooks/useConnections";
import ConnectionsComponent from "./cyDiagram/Connections.component";
import ConnectionsDiagramData from "../types/connectionsDiagram.type";
import HelpButtonComponent from "./shareComponent/helpButtonComponent";
import {useUser} from "../context/UserProvider/UserProvider";
import {useQuestionData} from "../requestHooks/useQuestionData";
import {useMostCorrelated} from "../requestHooks/useMostCorrelated";
import {useActualForMostCorrelated} from "../requestHooks/useActualForMostCorrelated";
import PredictCorrelationComponent from "./correlationsComponents/predictCorrelation.component";
import {PredictCorrelationEntry} from "../types/predictCorrelation.type";
import useGA from "../common/useGA4";


const UI_STRINGS = require('../common/ui-strings.json')

interface RouterProps { // type for `match.params`
    id: string; // must be type `string` since value comes from the URL
}

type Props = {
    params: RouterProps,
    question: QuestionType | null,
    custom_text: string | null,
    originalSearch: {
        originalSearchString: string,
        originalSearchResults: QuestionType[],
        originalSearchExamples: string[],
        originalActualForSearch: any[],
    } | null,
    previousQuestion: QuestionType | null,
};


const Question = (props: Props) => {
    const {authenticatedUser, requireAuth, accountParams} = useUser();
    const navigate = useNavigate();
    let {state} = useLocation();

    const org_id = "main";
    const {id} = props.params;
    const {sendGAEvent} = useGA();
    const [searchParams, setSearchParams] = useSearchParams();

    const {question, originalSearch, prevQuestion, custom_text, startTime, sharedTab,} = state ? state : {
        question: null,
        originalSearch: null,
        prevQuestion: null,
        custom_text: searchParams.get("text"),
        startTime: new Date().getTime(),
        sharedTab: searchParams.get("tab"),
    };
    // List all possible tab names
    const validTabNames = UiConfig.questionTabs; // Replace with your tab names
    // Check if the 'tab' parameter corresponds to a valid tab name. If not, default to 'statistics'.
    const sanitizedSharedTab = sharedTab ? sharedTab.normalize() : '';
    const sharedTabIsValid = validTabNames.includes(sanitizedSharedTab);

    const [tabKey, setTabKey] = useState<any>(sharedTabIsValid ? sanitizedSharedTab : 'statistics'); // Set the tabKey state

    const [baseQuestion, setBaseQuestion] = useState<QuestionType | null>(null);
    const [qStatistics, setQStatistics] = useState<QuestionStatisticsType | null>(null);
    const [qStudyQuestions, setQStudyQuestions] = useState<StudyQuestionType[] | null>(null);
    const {
        studyInfo,
        isLoadingStudyDetails,
        requestedIndex,
        correlationDetails,
        showStudyDetails,
        handleClose,
        handleShowStudyDetails
    } = useStudyDetails(qStudyQuestions);
    const {
        isLoadingQuestion,
        getQuestion,
        isLoadingQStatistics,
        getQuestionStatistics,
        isLoadingQStudyQuestions,
        getQStudyQuestions
    } = useQuestionData(org_id, id, handleGetQuestionResult, handleError, baseQuestion, custom_text, handleStatisticsResult, displayStudyQsResult)
    const {
        isLoadingMostCorrelated,
        requestMostCorrelated
    } = useMostCorrelated(org_id, handleMostCorrelatedResults, handleError, baseQuestion, custom_text)
    const [mostCorrelatedResult, setMostCorrelatedResult] = useState<QuestionType[] | null>(null);
    const controller = new AbortController();
    const abort_signal = controller.signal;
    const {
        isLoadingActualForMostCorrelated,
        requestActualForMostCorrelated
    } = useActualForMostCorrelated(org_id, handleActualForMostCorrelated, handleError, baseQuestion, custom_text, mostCorrelatedResult, abort_signal)
    const [actualForMostCorrelatedResult, setActualForMostCorrelatedResult] = useState<any[] | null>(null);
    const [showConnectionsDiagram, setShowConnectionsDiagram] = useState(false);
    const [predictCorrelationText, setPredictCorrelationText] = useState("");
    // todo: refactor correlationHistory to use Question Type
    const [predictCorrelationsData, setPredictCorrelationsData] = useState<PredictCorrelationEntry[]>([]);
    const [correlationExamples, setCorrelationExamples] = useState<QuestionType[]>([]);

    const [actualCorrRequestTexts, setActualCorrRequestTexts] = useState<string[]>([]);

    const [regressionStr, setRegressionStr] = useState<string | null>(null);
    const [connectionsStr, setConnectionsStr] = useState<string | null>(null);
    const [predictRegressionTexts, setPredictRegressionTexts] = useState<string[]>([]);
    const [predictRegressionResult, setPredictRegressionResult] = useState<PredictCorrelationEntry[] | null>(null);
    const [predictRegressionR2, setPredictRegressionR2] = useState<number | null>(null)
    const [networkData, setNetworkData] = useState<any[] | null>(null);
    const [connectionsData, setConnectionsData] = useState<ConnectionsDiagramData | null>(null);
    const [big5Data, setBig5Data] = useState<FactorsDataType | null>(null);
    const [generalFactorsData, setGeneralFactorsData] = useState<FactorsDataType | null>(null);
    const [subscalesData, setSubscalesData] = useState<FactorsDataType | null>(null);

    const [needToUpdate, setNeedToUpdate] = useState<boolean>(false);

    const {
        isLoadingRegression,
        requestRegression
    } = useLinearRegression(org_id, id, predictRegressionTexts, baseQuestion, custom_text, handleRegressionResult, handleError)

    const {
        isLoadingCorrelation,
        requestCorrelation
    } = useCorrelation(org_id, id, predictCorrelationText, baseQuestion, custom_text, handleCorrelationResult, handleError)

    const {
        isLoadingConnections,
        fetchConnections, connectionsStage
    } = useConnections(org_id, id, connectionsStr, baseQuestion, custom_text, handleConnectionsData, handleError)

    useEffect(() => {
        setNeedToUpdate(true);
    }, [accountParams])

    function fillInitialCorrelations() {
        if (state && state.originalSearch && !state.prevQuestion && custom_text === null) {
            let originalSearchString = state.originalSearch.originalSearchString;
            let originalSearchResults: QuestionType[] = state.originalSearch.originalSearchResults;
            let searchResultQEntity = originalSearchResults.filter(q => {
                return q.canonical_text === baseQuestion?.canonical_text
            });
            let corr_dict: any;
            if (searchResultQEntity[0] && searchResultQEntity[0]["score"] && searchResultQEntity[0]["score"] !== undefined) corr_dict = searchResultQEntity[0]["score"];

            let initialHistory: PredictCorrelationEntry | null;
            if (corr_dict !== undefined)
                initialHistory = {
                    text: originalSearchString,
                    correlation: corr_dict["correlation"],
                    is_surprising: corr_dict["is_surprising"],
                    what_is_surprising: corr_dict["what_is_surprising"]
                };
            else initialHistory = null;
            let newHistory: PredictCorrelationEntry[] = [...predictCorrelationsData];
            if (initialHistory && corr_dict) newHistory.push(initialHistory);
            setPredictCorrelationsData(newHistory);

        }
        if (state && state.prevQuestion && !custom_text) {
            let textForHistory = prevQuestion.canonical_text;
            let corrForHistory = prevQuestion.score;
            let initialHistory: PredictCorrelationEntry | null;
            if (corrForHistory !== undefined)
                initialHistory = {text: textForHistory, ...corrForHistory};

            else initialHistory = null;
            let newHistory: PredictCorrelationEntry[] = [];
            if (initialHistory) newHistory.push(initialHistory);
            setPredictCorrelationsData(newHistory);
            setNeedToUpdate(true);
        }
        setNeedToUpdate(false);

    }


    const {
        isFetching: isLoadingBig5,
        refetch: requestBig5
    } = useQuery<any>(
        "query-request-big5",
        async () => {

            let request_texts = Object.values(UiConfig.factors.big5);
            let base_text;
            custom_text ? base_text = custom_text : base_text = baseQuestion?.canonical_text
            return await QuestionDataService.getLinearRegressionForText(org_id, base_text, request_texts);

        },
        {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleBig5Results(res),
            onError: (err: any) => handleError(err, 'regression'),
        }
    )


    const {
        isFetching: isLoadingGeneralFactors,
        refetch: requestGeneralFactors
    } = useQuery<any>(
        "query-request-general-factors",
        async () => {

            let request_texts = Object.values(UiConfig.factors.general);
            let base_text;
            custom_text ? base_text = custom_text : base_text = baseQuestion?.canonical_text
            return await QuestionDataService.getLinearRegressionForText(org_id, base_text, request_texts);

        },
        {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleGenefalFactorsResults(res),
            onError: (err: any) => handleError(err, null),
        }
    )


    const {
        isFetching: isLoadingSubscales,
        refetch: requestSubscales
    } = useQuery<any>(
        "query-request-subscales",
        async () => {
            let base_text: string | undefined;
            custom_text ? base_text = custom_text : base_text = baseQuestion?.canonical_text
            let request_texts = Object.values(UiConfig.factors.subscales).filter(t => t !== base_text);
            return await QuestionDataService.getSubscalesForText(org_id, base_text, request_texts);

        },
        {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleSubscalesResults(res),
            onError: (err: any) => handleError(err, null),
        }
    )


    const {
        isFetching: isLoadingStudyCorrelations,
        refetch: requestStudyCorrelations
    } = useQuery<number[], Error>(
        "query-request-study-correlations",
        async () => {
            if (actualCorrRequestTexts.length > 0) {
                if (!custom_text && baseQuestion) {
                    return await QuestionDataService.getStudyCorrelationsForTexts(abort_signal, org_id, baseQuestion.canonical_text, actualCorrRequestTexts);
                }
            }
        },
        {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleStudyCorrelationResults(res),
            onError: (err: any) => handleError(err, null),
        }
    )


    const {
        isFetching: isLoadingCorrelationExamples,
        refetch: requestCorrelationExamples
    } = useQuery<number[], Error>(
        "query-request-correlation-examples",
        async () => {
            if (predictCorrelationsData.length > 1) {
                return await QuestionDataService.getMostCorrelatedForText(org_id, predictCorrelationsData[0].text, accountParams);
            }
        }
        ,
        {
            enabled: predictCorrelationsData.length > 1,
            retry: 2,
            onSuccess: (res) => handleCorrelationExamplesResults(res),
            onError: (err: any) => handleError(err, null),
        }
    )


    const {
        isFetching: isLoadingNetwork,
        refetch: requestNetworkData
    } = useQuery<any[], Error>(
        "query-request-network-data",
        async () => {
            let _text = '';
            (baseQuestion && !custom_text) ? _text = baseQuestion.canonical_text : _text = custom_text
            return await QuestionDataService.getNetwork(org_id, _text, accountParams);

        },
        {
            enabled: false,
            retry: 3,
            onSuccess: (res) => handleNetworkData(res),
            onError: (err: any) => handleError(err, null),
        }
    )


    function handleGetQuestionResult(res: any) {
        if (res) setBaseQuestion(res);
    }

    function handleStatisticsResult(res: any) {
        if (res) {
            setQStatistics(res);
        }
    }


    function handleError(err: unknown, sourceTab: string | null) {
        // setApiDown(true);
        console.log("DEBUG ERROR: ", err)

        const inputBlock = document.getElementById(`${sourceTab}ErrorBlock`);
        if (inputBlock) {
            inputBlock.innerHTML = `
            <div class="alert alert-danger" role="alert">
                <p>An error occurred while processing your request.</p>
                <p>${UI_STRINGS.errors.defaultMessage}</p>
            </div>
        `;
        }

        if (sourceTab) {
            switch (sourceTab) {
                case 'regression':
                    console.error("Error in Regression Tab:", err);
                    setPredictRegressionTexts([]);
                    break;
                case 'correlation':
                    console.error("Error in Correlation Tab:", err);
                    setPredictCorrelationText('');
                    break;
                case 'connections':
                    console.error("Error in Connections Tab:", err);
                    setConnectionsStr(null);
                    break;
                default:
                    console.error("Unknown tab:", sourceTab, err);
                    break;
            }
        } else {
            console.error("Source tab is null:", err);
        }
    }

    function displayStudyQsResult(studyQs: any) {
        if (studyQs) {
            setQStudyQuestions(studyQs);
        } else setQStudyQuestions(null);
    }


    function handleCorrelationResult(res: any) {
        console.log("DEBUG handleCorrelationResult: ", res);
        let actualCorrelations;
        // studyCorrelationResult ? actualCorrelations = studyCorrelationResult.actualCorrelations : actualCorrelations = undefined;
        let result: PredictCorrelationEntry = {
            text: predictCorrelationText,
            ...res,
            actualCorrelations: actualCorrelations
        };
        if (result) {
            let newHistory: PredictCorrelationEntry[] = [...predictCorrelationsData];
            newHistory.unshift(result);
            setPredictCorrelationsData(newHistory);
            let entriesMissingActualCorr = newHistory.filter((e) => e.actualCorrelations == undefined);
            let actualCorrTexts = entriesMissingActualCorr.map(e => e.text);
            setActualCorrRequestTexts(actualCorrTexts);

        }
        let input: any = document.getElementById("correlationInput");
        input.value = null;

    }


    function handleSubscalesResults(res: any) {
        setSubscalesData(res);
    }


    function handleGenefalFactorsResults(res: any) {

        let regArr = res.linear_regression.map((r: number) => Utils.formatCorrelation(r));
        let r2 = res.accuracy_r2;
        if (regArr.length === Object.keys(UiConfig.factors.general).length) {
            setGeneralFactorsData(res);
        }
    }

    function handleBig5Results(res: any) {
        let regArr = res.linear_regression.map((r: number) => Utils.formatCorrelation(r));
        let r2 = res.accuracy_r2;
        if (regArr.length === Object.keys(UiConfig.factors.big5).length) {
            setBig5Data(res)
        }
    }

    function handleStudyCorrelationResults(res: any) {
        let entriesMissingActualCorr = predictCorrelationsData.filter(e => e.actualCorrelations === undefined);
        if (res && res.response && entriesMissingActualCorr.length > 0) {
            for (let [i, r] of res.response.entries()) {
                let entry = entriesMissingActualCorr[i];
                let actualCorrelations = r.map((studyR: any) => ({
                    study_id: studyR.study_id,
                    correlation: Number(studyR.correlation),
                    sample_size: Number(studyR.sample_size),
                }));

                entry.actualCorrelations = [{
                    text: entry.text,
                    corr_data: actualCorrelations,
                }];
            }

            let newHistory: PredictCorrelationEntry[] = predictCorrelationsData.map(e => {
                let index = actualCorrRequestTexts.indexOf(e.text);
                return index > -1 ? entriesMissingActualCorr[index] : e;
            });

            setActualCorrRequestTexts([]);
            setPredictCorrelationsData(newHistory);
        }
    }

    function handleNetworkData(res: any) {
        if (res && res.length) {
            setNetworkData(res);
        }

    }


    function handleConnectionsData(res: ConnectionsDiagramData) {

        if (res) {
            setConnectionsStr(null);
            let input: any = document.getElementById("connectionsInput");
            input.value = null;
            setConnectionsData(res as ConnectionsDiagramData);
        }
    }

    function getRegressionExamples() {
        let always_include: QuestionType[] = [{
            canonical_text: "woman",
            universal_q_id: "",
            question_type: "dummy",
            org_id: "main"
        }, {canonical_text: "age", universal_q_id: "", question_type: "dummy", org_id: "main"}]
        // noinspection TypeScriptValidateTypes
        let surprisesInMostCorrelatedAll = mostCorrelatedResult?.filter((q: QuestionType) =>
            q.score && q.score.is_surprising && q.score.what_is_surprising)
        if (surprisesInMostCorrelatedAll && surprisesInMostCorrelatedAll.length > 0) {
            let surprisesInMostCorrelated = surprisesInMostCorrelatedAll?.filter((q) =>
                q.score && q.score.is_surprising && q.score.what_is_surprising && q.score.what_is_surprising.includes("low relatedness") && !predictRegressionTexts.includes(q.canonical_text))
            if (surprisesInMostCorrelated.length > 0) return [...surprisesInMostCorrelated, ...always_include].filter((q) => !predictRegressionTexts.includes(q.canonical_text));
            else return [...surprisesInMostCorrelatedAll, ...always_include].filter((q) => !predictRegressionTexts.includes(q.canonical_text));
        } else return always_include.filter((q) => !predictRegressionTexts.includes(q.canonical_text));
    }


    function handleCorrelationExamplesResults(res: any) {
        if (res && res.length > 0) {
            let all: QuestionType[] = res.filter((q: QuestionType) => !predictCorrelationsData.map(h => h.text).includes(q.canonical_text));
            if (all) {
                let positive = all.filter((q: QuestionType) => q.score && q.score.correlation > 0);
                let negative = all.filter((q: QuestionType) => q.score && q.score.correlation < 0);
                let total = [...positive.slice(0, 2), ...negative.slice(0, 2)];
                setCorrelationExamples(total)
            }

        }
    }


    function removeRegressionItem(index: number) {
        ReactGA.event({
            category: "Predict regression",
            action: "Predict regression: An item removed from regression list",
        })
        let t = [...predictRegressionTexts];
        t.splice(index, 1);
        setPredictRegressionTexts(t);
    }

    function clearRegressionClicked() {
        ReactGA.event({
            category: "Predict regression",
            action: "Predict regression: User clicked Clear All",
        })
        setRegressionStr(null);
        setPredictRegressionTexts([]);
        setPredictRegressionResult(null);
    }

    function checkRegressionAndAdd(str: string) {
        setRegressionStr(str);
    }

    function handleRegressionResult(res: any) {
        setRegressionStr(null);
        let input: any = document.getElementById("regressionInput");
        input.value = null;
        let regArr = res.linear_regression.map((r: number) => Utils.formatCorrelation(r));
        let r2 = res.accuracy_r2;
        let t = [];
        for (let i = 0; i < predictRegressionTexts.length; i++) {
            // todo: fix types
            let entry: any = {text: predictRegressionTexts[i], corr_num: regArr[i]}
            t.push(entry);
        }
        setPredictRegressionResult(t);
        setPredictRegressionR2(r2);

    }

    function handleMostCorrelatedResults(res: any | undefined) {
        if (res && res.length) {

            let resultsCombined: QuestionType[] = res;

            for (let i = 0; i < resultsCombined.length; i++) {
                let corr_dict: any;
                resultsCombined[i].score ? corr_dict = resultsCombined[i].score : corr_dict = {};
                if (corr_dict) resultsCombined[i].sort_score = Math.abs(corr_dict["correlation"]);
            }
            resultsCombined.sort(function (a, b) {
                if (b.sort_score && a.sort_score)
                    return b.sort_score - a.sort_score
                else return 0
            });
            let final = resultsCombined.filter(q => q.universal_q_id !== id)
            setMostCorrelatedResult(final);
        }
    }

    function handleActualForMostCorrelated(res: any | undefined) {
        if (res) {
            if (res["for"] === baseQuestion?.canonical_text) {
                let filtered = res["response"].filter((r: any[]) => r.length > 0);
                if (filtered.length > 0 && mostCorrelatedResult) {
                    let actual = res["response"].map((r: any) => {
                        if (r.length > 0) {
                            let r0 = r[0];
                            return {
                                "text": r0.question_text_1 == baseQuestion?.canonical_text ? r0.question_text_2 : r0.question_text_1,
                                "corr_data": r.map((s: any) => ({
                                    correlation: s.correlation,
                                    study_id: s.study_id,
                                    sample_size: s.sample_size
                                }))
                            }
                        } else return {}
                    })
                    let actualMCResults = [];
                    for (let m of mostCorrelatedResult) {
                        let current = actual.filter((a: any) => (a && a.text && a.text == m.canonical_text))[0]
                        actualMCResults.push(current)
                    }
                    setActualForMostCorrelatedResult(actualMCResults);
                } else setActualForMostCorrelatedResult([]);
            } else requestActualForMostCorrelated();
        }
    }

    function questionSelected(q: QuestionType) {
        try {
            clearAll();
            let prevQ = baseQuestion;
            if (prevQ) prevQ.score = q.score;
            setBaseQuestion(q);
            sendGAEvent(
                "Navigation",
                "Most correlated: Navigating to another question",
                q.canonical_text,
            )

            if (q && q.universal_q_id) {
                navigate("/questions/" + q.universal_q_id, {
                    replace: false,
                    state: {
                        question: q,
                        originalSearch: originalSearch,
                        prevQuestion: prevQ,
                        startTime: new Date().getTime(),
                    }
                });
            } else {
                throw new Error('Question is not defined or missing universal_q_id!');
            }

        } catch (error) {
            // console.error(error);
            // handle or report error
        }
    }

    function clearAll() {
        setQStatistics(null);
        setMostCorrelatedResult(null);
        setActualForMostCorrelatedResult(null);
        setActualCorrRequestTexts([]);
        setQStudyQuestions(null);
        setPredictRegressionResult(null);
        setPredictRegressionTexts([]);
        setTabKey("statistics");
        setBig5Data(null);
        setGeneralFactorsData(null);
        setNetworkData(null);
        setConnectionsData(null);
        setSubscalesData(null);
    }


    function backToSearch() {
        ReactGA.event({category: 'Navigation', action: 'Clicked Back to Search'})
        let options = originalSearch ? {
            replace: false,
            state: {originalSearch: originalSearch},
        } : {replace: false}
        navigate("/", options);
    }

    function backToPrevious() {
        ReactGA.event({category: 'Navigation', action: 'Clicked Back to Previous'})

        let options = originalSearch ? {
            replace: false,
            state: {originalSearch: originalSearch},
        } : {replace: false}
        navigate(-1);
    }

    function getScoreStyle(score: number | undefined) {
        let style: string = '';
        if (score)
            score < 0 ? style = 'red' : style = ' ';
        return style;

    }

    function filterCorrelationHistory(item: any) {
        if (!custom_text && baseQuestion) {
            return item.text !== baseQuestion.canonical_text
        }
        if (custom_text) {
            return item.text !== custom_text
        }

    }

    function getExcludeResultsRegression(): string[] {
        const regressionResults = predictRegressionResult?.map(item => item.text).filter(Boolean) || [];
        const additionalText = baseQuestion?.canonical_text ?? custom_text;

        return [...regressionResults, additionalText];
    }

    const handleTabChange = (newTabKey: string | null) => {
        // preserve all existing params
        let params = new URLSearchParams(searchParams.toString());

        if (newTabKey === null) {
            // remove 'tab' param if newTabKey is null
            params.delete('tab');
        } else {
            // set new value for 'tab' param
            params.set('tab', newTabKey);
        }


        // set modified search params without causing a navigation
        setSearchParams(params, {replace: true});

        // set tab key state
        setTabKey(newTabKey);
    };

    function excludeInputStrings(tabType: string): string[] {
        const mainStr = baseQuestion ? standardizeTexts(baseQuestion.canonical_text) : custom_text;
        let additionalStr: string[] = [];

        switch (tabType) {
            case 'correlation':
                additionalStr = [predictCorrelationText];
                break;
            case 'connections':
                if (connectionsData)
                    additionalStr = [...connectionsData.statements];
                break;
            case 'regression':
                additionalStr = [...predictRegressionTexts];
                break;
        }

        // Filter out null values and return the array
        return [mainStr, ...additionalStr].filter(Boolean);
    }


    useEffect(() => {
        if (authenticatedUser !== undefined && accountParams && !accountParams.got_updated) {
            if (!custom_text) {
                if (question) {
                    setBaseQuestion(question);
                } else if (id) {
                    getQuestion();
                }
            } else if (custom_text && custom_text.length > 0) {
                let dummy_q: QuestionType = {
                    universal_q_id: null,
                    canonical_text: custom_text,
                    question_type: 'User-typed custom text',
                    org_id: 'main',
                }
                setBaseQuestion(dummy_q);
            }
        }

        return () => {
            // console.clear();
            controller.abort();
        }
    }, [custom_text, id, question, accountParams]);


    useEffect(() => {
        ReactGA.event({category: 'Navigation', action: 'Loaded tab: ' + tabKey})
    }, [tabKey]);

    useEffect(() => {
        if (baseQuestion !== null) {
            fillInitialCorrelations();
            getQuestionStatistics();
            getQStudyQuestions();
        }
        return () => {
            // console.clear();
            controller.abort();
        }
    }, [baseQuestion, needToUpdate]);


    useEffect(() => {
        if (baseQuestion !== null) {
            requestMostCorrelated();
        }
        return () => {
            // console.clear();
            controller.abort();
        }
    }, [baseQuestion]);


    useEffect(() => {
        if (baseQuestion !== null && networkData === null && tabKey === 'network') {
            requestNetworkData();
        }
    }, [baseQuestion, tabKey]);


    useEffect(() => {
        if (baseQuestion !== null && big5Data === null) {
            requestBig5();
        }
        return () => {
        }
    }, [baseQuestion]);

    useEffect(() => {
        if (baseQuestion !== null && generalFactorsData === null) {
            requestGeneralFactors();
        }
        return () => {
        }
    }, [baseQuestion]);


    useEffect(() => {
        if (baseQuestion !== null && subscalesData === null) {
            requestSubscales();
        }
        return () => {
        }
    }, [baseQuestion]);


    useEffect(() => {
        // setShowChart(1);
    }, [qStatistics]);


    useEffect(() => {
        if (mostCorrelatedResult && mostCorrelatedResult.length > 0 && !custom_text) {
            requestActualForMostCorrelated();
        }
    }, [mostCorrelatedResult])


    useEffect(() => {
        if (mostCorrelatedResult && mostCorrelatedResult.length > 0) {
            getRegressionExamples();
        }
    }, [mostCorrelatedResult])


    useEffect(() => {
        //check if we have study questions for the case of a universal question (custom_text=null)
        if (!custom_text && state) {

            if (state.prevQuestion) setActualCorrRequestTexts([state.prevQuestion.canonical_text]);
            if (state.originalSearch && !state.prevQuestion) setActualCorrRequestTexts([state.originalSearch.originalSearchString]);
        }

    }, [qStudyQuestions]);


    useEffect(() => {

    }, [isLoadingConnections, connectionsData]);


    useEffect(() => {
        let t = [...predictRegressionTexts];
        if (regressionStr !== null) t.push(regressionStr);
        setPredictRegressionTexts(t);

    }, [regressionStr])


    useEffect(() => {
        if (predictRegressionTexts !== null && predictRegressionTexts.length > 0) {
            requestRegression();
        }
    }, [predictRegressionTexts])


    useEffect(() => {
        if (predictCorrelationText && predictCorrelationText.length > 0) {
            requestCorrelation();
        }
    }, [predictCorrelationText])


    useEffect(() => {
        if (connectionsStr && connectionsStr.length > 0) {
            setConnectionsData(null);
            fetchConnections();
        }
    }, [connectionsStr]);


    useEffect(() => {
        if (actualCorrRequestTexts.length > 0 && baseQuestion?.universal_q_id !== null) {
            requestStudyCorrelations();
        }
    }, [actualCorrRequestTexts])


    useEffect(() => {
        if (predictCorrelationsData.length > 1) {
            requestCorrelationExamples();
        }
    }, [predictCorrelationsData]);

    useEffect(() => {
        // Set the state based on whether ConnectionsDiagram should be displayed.
        if (connectionsData && connectionsData.connections && connectionsData.connections.length > 0 && tabKey === 'connections') {
            setShowConnectionsDiagram(true);
        } else {
            setShowConnectionsDiagram(false);
        }
    }, [connectionsData]);

    return (
        <div className="p-0">
            <div className="d-flex w-100 justify-content-between">
                <div className="">
                    <div className={"backNavigation"} onClick={backToSearch}><i className="bi bi-arrow-left"></i><span
                        className="text-decoration-underline">Back
                        to Search</span>
                    </div>
                    {prevQuestion && (
                        <div className={"backNavigation heightSmall"} onClick={backToPrevious}><i
                            className="bi bi-arrow-left"></i><span
                            className="text-decoration-underline">Previous question</span></div>
                    )}
                    {!prevQuestion && (
                        <div className={"backNavigation heightSmall"}></div>
                    )}

                </div>

            </div>
            <div className="row p-0 pt-3 pt-lg-4">
                <div className="col">
                    <div className="row">
                        {isLoadingQuestion && (
                            <LoadingGif width={100}/>

                        )}

                    </div>
                    {!isLoadingQuestion && (
                        <div>
                            <h2 className="mb-0 mt-1">{baseQuestion ? baseQuestion?.canonical_text : custom_text}</h2>
                            <div className="col">Question
                                type: {baseQuestion ? baseQuestion?.question_type : 'User-typed custom text'}</div>
                        </div>

                    )
                    }

                </div>

                <Tabs id="question-data-tabnav"
                      justify
                      activeKey={tabKey}
                      onSelect={handleTabChange}>
                    <Tab eventKey="statistics" title="Statistics" className="myTab">
                        <Tab.Content>
                            <div className="statisticsDiv pt-3 pb-5">
                                {isLoadingQStatistics && (
                                    <LoadingGif width={80}/>

                                )}
                                {!isLoadingQStatistics && !isLoadingQStudyQuestions && !isLoadingQuestion && qStatistics !== null && baseQuestion !== null && (
                                    <div className="position-relative pt-3">
                                        <div className='d-flex justify-content-end  pb-4'
                                             style={{position: 'absolute', top: 0, right: 0}}>

                                            <HelpButtonComponent tab={'statistics'}/>
                                            <ShareBlockOffCanvasComponent statement={baseQuestion.canonical_text}
                                                                          sharedTab={'statistics'}
                                                                          correlations={[]} regressionResult={[]}/>
                                        </div>

                                        <div
                                            className="statsWrapper d-flex justify-content-start flex-wrap">
                                            <div id="chartEl" className="chart pe-3">
                                                <div
                                                    className="pt-2 pb-4 text-center w-100 d-flex justify-content-center">
                                                    <h4>{qStatistics ? 'Distribution of responses' : ''}</h4>
                                                </div>
                                                {/*need to hide chart when statistics tab not selected*/}
                                                {qStatistics && tabKey === "statistics" &&
                                                    <StatsChart
                                                        key={(baseQuestion && qStatistics && qStudyQuestions) ? 'statschart' + baseQuestion?.universal_q_id : 1}
                                                        qStatistics={qStatistics}
                                                        qStudyQuestions={qStudyQuestions}
                                                        dataType={baseQuestion?.question_type || ''}
                                                        startTime={startTime}/>
                                                }
                                            </div>
                                            <StatsTable
                                                key={baseQuestion && qStatistics && qStudyQuestions ? baseQuestion?.universal_q_id : 1}
                                                qStatistics={qStatistics}
                                                qStudyQuestions={qStudyQuestions}
                                                dataType={baseQuestion?.question_type || ''}
                                                startTime={startTime} handleShowStudyDetails={handleShowStudyDetails}/>
                                        </div>
                                        {baseQuestion && baseQuestion.universal_q_id !== null && (
                                            <RelatedArticles question_text={baseQuestion.canonical_text}/>
                                        )}


                                    </div>
                                )}

                            </div>

                        </Tab.Content>
                    </Tab>
                    <Tab className="myTab" eventKey="mostCorrelated" title="Most Correlated"
                    >
                        <Tab.Content>
                            {baseQuestion && (
                                <div className="pt-3 pb-5">
                                    {isLoadingMostCorrelated && (
                                        <LoadingGif width={80}/>
                                    )}
                                    {!isLoadingMostCorrelated && mostCorrelatedResult && mostCorrelatedResult.length > 0 && (
                                        <div>
                                            <div className='d-flex justify-content-end pb-4'>

                                                <HelpButtonComponent tab={'mostCorrelated'}/>
                                                <ShareBlockOffCanvasComponent statement={baseQuestion.canonical_text}
                                                                              sharedTab={'mostCorrelated'}
                                                                              correlations={[]} regressionResult={[]}/>
                                            </div>
                                            <MostCorrelated custom_text={custom_text}
                                                            mainQLink={baseQuestion}
                                                            isLoadingMostCorrelated={isLoadingMostCorrelated}
                                                            isLoadingStudyCorrelations={isLoadingActualForMostCorrelated}
                                                            mostCorrelatedResult={mostCorrelatedResult}
                                                            questionSelected={questionSelected}
                                                            actualCorrelations={actualForMostCorrelatedResult}
                                                            debugMode={false}
                                                            accessLevel={2}
                                                            handleShowStudyDetails={handleShowStudyDetails}
                                            />
                                        </div>

                                    )}


                                </div>
                            )}

                        </Tab.Content>
                    </Tab>

                    <Tab className="myTab position-relative " eventKey="predictCorrelation"
                         title="Predict Correlation">
                        <Tab.Content>
                            <div className=" position-relative mt-3">
                                {/*top block*/}
                                <div className="top-row-block-wrapper">
                                    <div className="top-row-block">
                                        <div
                                            className={'regular-input-block'}>
                                            <InputBlockComponent type={'correlation'}
                                                                 setFunction={setPredictCorrelationText}
                                                                 org_id={'main'} excludeExamples={[]}
                                                                 forbiddenInputStrings={excludeInputStrings('correlation')}
                                                                 isLoadingResults={isLoadingCorrelation}
                                                                 questionsForExamples={() => correlationExamples}
                                                                 isLoadingExamples={isLoadingCorrelationExamples}
                                                                 custom_text={custom_text}
                                                                 lockRequests={isLoadingCorrelation}/>
                                        </div>

                                        {baseQuestion && (
                                            <div className="share-block-container">
                                                <div className="same-height-flex-container">
                                                    <HelpButtonComponent tab={'predictCorrelation'}/>
                                                    <ShareBlockOffCanvasComponent
                                                        statement={baseQuestion.canonical_text}
                                                        sharedTab={'predictCorrelation'}
                                                        correlations={predictCorrelationsData.filter((item: PredictCorrelationEntry) => filterCorrelationHistory(item))}
                                                        regressionResult={[]}
                                                    />

                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/*results block*/}
                                <div className="results-block">

                                    {isLoadingCorrelation && (
                                        <LoadingGif width={80}/>

                                    )}
                                    <div id='correlationErrorBlock'></div>
                                    <PredictCorrelationComponent custom_text={custom_text}
                                                                 actualCorrelations={actualForMostCorrelatedResult}
                                                                 baseQuestion={baseQuestion}
                                                                 filterCorrelationHistory={filterCorrelationHistory}
                                                                 handleShowStudyDetails={handleShowStudyDetails}
                                                                 isLoadingStudyCorrelations={isLoadingStudyCorrelations}
                                                                 predictCorrelationsData={predictCorrelationsData}
                                                                 questionSelected={questionSelected}/>

                                </div>
                            </div>
                        </Tab.Content>
                    </Tab>
                    <Tab className="myTab position-relative" eventKey="predictLinearRegression"
                         title="Linear Regression">
                        <Tab.Content className="position-relative mt-3">
                            {/*top block*/}
                            <div className="top-row-block-wrapper">
                                <div className="top-row-block">
                                    <div className="regular-input-block">
                                        <InputBlockComponent type={'regression'}
                                                             org_id={'main'}
                                                             excludeExamples={getExcludeResultsRegression()}
                                                             questionsForExamples={getRegressionExamples}
                                                             forbiddenInputStrings={excludeInputStrings('regression')}
                                                             isLoadingResults={isLoadingRegression}
                                                             isLoadingExamples={isLoadingMostCorrelated}
                                                             custom_text={custom_text}
                                                             setFunction={checkRegressionAndAdd}
                                                             lockRequests={isLoadingRegression}
                                        />
                                    </div>


                                    {baseQuestion && (
                                        <div className='share-block-container'>
                                            <HelpButtonComponent tab={'predictLinearRegression'}/>
                                            <ShareBlockOffCanvasComponent statement={baseQuestion.canonical_text}
                                                                          sharedTab={'predictLinearRegression'}
                                                                          correlations={[]}
                                                                          regressionResult={predictRegressionResult}
                                                                          r2={predictRegressionR2}/>

                                        </div>
                                    )}
                                </div>
                            </div>
                            {/*results block*/}
                            <div className="results-block">
                                {isLoadingRegression && (
                                    <LoadingGif width={80}/>

                                )}
                                <div id='regressionErrorBlock'></div>


                                {predictRegressionResult !== null && predictRegressionResult.length > 0 && (
                                    <div className="correlationHistoryWrapper container pt-5">

                                        <div className="row pb-3">
                                            <div className="col">
                                                <div className={"lead pb-4"}>Predicted Linear Regression Coefficients
                                                </div>
                                                <table>
                                                    <thead></thead>
                                                    <tbody>
                                                    {predictRegressionResult.map((item: any, index: number) =>
                                                        (
                                                            <tr key={index}
                                                                className={"p-5"}>
                                                                <td className={"p-2 pe-3  text-center " + " " + getScoreStyle(item.corr_num)}>{item.corr_num}</td>
                                                                <td className="p-2 pe-3">{item.text}</td>
                                                                {predictRegressionResult.length > 1 && (
                                                                    <td>
                                                                        <button className="btn btnRemoveRegItem"
                                                                                onClick={() => removeRegressionItem(index)}>
                                                                            <i className="bi bi-x-circle"></i>
                                                                        </button>
                                                                    </td>
                                                                )}

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {predictRegressionR2 && (
                                            <div className="row p-2">
                                                <div className="col p-2 pb-3 r2div">Model R<span
                                                    className="superscript ">2</span>&nbsp;=&nbsp;{predictRegressionR2.toFixed(4)}
                                                </div>

                                            </div>
                                        )}
                                        <div className="pt-5">
                                            <button className="btn btn-outline-secondary"
                                                    onClick={clearRegressionClicked}>Clear results
                                            </button>
                                        </div>
                                    </div>

                                )}

                            </div>
                        </Tab.Content>
                    </Tab>
                    <Tab className="myTab" eventKey="network" title="Network">
                        <Tab.Content>
                            {isLoadingNetwork && (
                                <LoadingGif width={80}/>
                            )

                            }

                            {baseQuestion && !isLoadingNetwork && networkData !== undefined
                                && networkData && networkData.length > 0 && tabKey === 'network' && (
                                    <div className={"pt-3 position-relative"}>
                                        <div className={"pt-3 position-relative"}>

                                            <NetworkComponent data={networkData} tabType={'network'}
                                                              initialNodesLimit={6}/>
                                            <div className='d-flex justify-content-end pb-0'
                                                 style={{position: 'absolute', top: 0, right: 0}}>
                                                <HelpButtonComponent tab={'network'}/>
                                                <ShareBlockOffCanvasComponent statement={baseQuestion.canonical_text}
                                                                              sharedTab={'network'}
                                                                              correlations={[]} regressionResult={[]}/>

                                            </div>
                                        </div>

                                    </div>

                                )
                            }

                            {!isLoadingNetwork && networkData !== null && networkData.length === 0 && (
                                <div className="p-5 lead">{UI_STRINGS.questionTabs.networkNoConnections}</div>
                            )
                            }


                        </Tab.Content>
                    </Tab>
                    <Tab className="myTab position-relative" eventKey="connections" title="Connections">
                        <Tab.Content className="position-relative mt-3">
                            {/*top block*/}
                            <div className="top-row-block-wrapper">
                                <div className="top-row-block">
                                    <div
                                        className={showConnectionsDiagram ? 'small-input-block' : 'regular-input-block'}>
                                        <InputBlockComponent
                                            type={'connections'}
                                            setFunction={setConnectionsStr}
                                            org_id={org_id}
                                            excludeExamples={[]}
                                            forbiddenInputStrings={excludeInputStrings('connections')}
                                            isLoadingResults={isLoadingConnections}
                                            questionsForExamples={() => []}
                                            isLoadingExamples={false}
                                            custom_text={custom_text}
                                            lockRequests={isLoadingConnections}
                                        />
                                    </div>

                                    {baseQuestion && (
                                        <div className="share-block-container">
                                            <HelpButtonComponent tab={'connections'}/>
                                            <ShareBlockOffCanvasComponent
                                                statement={baseQuestion.canonical_text}
                                                sharedTab={'connections'}
                                                correlations={[]}
                                                regressionResult={[]}
                                            />

                                        </div>
                                    )}
                                </div>
                            </div>
                            {/*results block*/}
                            <div className="results-block">
                                {isLoadingConnections ? (
                                    <div>
                                        <LoadingGif width={80}/>
                                        <div>{UI_STRINGS.questionTabs.connectionsFetching}</div>
                                        <div className="connectionsAlertStage">{connectionsStage}</div>
                                    </div>
                                ) : (
                                    <>
                                        <div id='connectionsErrorBlock'></div>
                                        {baseQuestion && connectionsData && connectionsData.connections && connectionsData.connections.length > 0 && tabKey === 'connections' ? (
                                            <div className="pt-3 position-relative">
                                                <NetworkComponent data={connectionsData} tabType={'connections'}
                                                                  initialNodesLimit={4}/>
                                            </div>
                                        ) : null}
                                        {!isLoadingConnections && connectionsData !== null && connectionsData.connections && connectionsData.connections.length === 0 ? (
                                            <div
                                                className="p-5 lead">{UI_STRINGS.questionTabs.connectionsNoConnections}</div>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </Tab.Content>
                    </Tab>
                    <Tab className="myTab" eventKey="factors" title="Factors">
                        <Tab.Content>
                            {baseQuestion && (isLoadingBig5 || isLoadingGeneralFactors) && (
                                <LoadingGif width={80}/>
                            )}

                            {baseQuestion && !isLoadingBig5 && (big5Data !== null && generalFactorsData !== null) && (
                                <div className="pt-3 position-relative">
                                    <div className="pt-3 position-relative">

                                        {/*<div className="red lead factorsWarning">{UI_STRINGS.questionTabs.warning}*/}
                                        {/*</div>*/}
                                        {generalFactorsData && (
                                            <div className="factorsBlock">
                                                <div
                                                    className="factorsHeading" style={{marginBottom: '.5rem'}}>
                                                    <h5>{UI_STRINGS.questionTabs.generalFactorsHeading}</h5>
                                                    {generalFactorsData && generalFactorsData.accuracy_r2 && (
                                                        <div className="factorsModelR">
                                                            <div className="r2div">R<span
                                                                className="superscript ">2</span>&nbsp;=&nbsp;{generalFactorsData.accuracy_r2.toFixed(4)}
                                                            </div>

                                                        </div>
                                                    )}
                                                </div>
                                                <FactorsChart data={generalFactorsData}
                                                              factorsSet={UiConfig.factors.general}/>


                                            </div>
                                        )}

                                        {big5Data && (
                                            <div className="factorsBlock">
                                                <div
                                                    className="factorsHeading" style={{marginBottom: '.5rem'}}>
                                                    <h5>{UI_STRINGS.questionTabs.bi5Heading}</h5>
                                                    {big5Data && big5Data.accuracy_r2 && (
                                                        <div className="factorsModelR">
                                                            <div className="r2div">R<span
                                                                className="superscript ">2</span>&nbsp;=&nbsp;{big5Data.accuracy_r2.toFixed(4)}
                                                            </div>

                                                        </div>
                                                    )}
                                                </div>
                                                <FactorsChart data={big5Data} factorsSet={UiConfig.factors.big5}/>

                                            </div>
                                        )}


                                        {subscalesData && (
                                            <div className="factorsBlock">
                                                <div
                                                    className="factorsHeading" style={{marginBottom: '.5rem'}}>
                                                    <h5 className=''>{UI_STRINGS.questionTabs.subscalesHeading}</h5>
                                                    {subscalesData && subscalesData.accuracy_r2 && (
                                                        <div className="factorsModelR">
                                                            <div className="r2div">R<span
                                                                className="superscript ">2</span>&nbsp;=&nbsp;{subscalesData.accuracy_r2.toFixed(4)}
                                                            </div>

                                                        </div>
                                                    )}
                                                </div>
                                                <FactorsChart data={subscalesData}
                                                              factorsSet={UiConfig.factors.subscales}/>

                                            </div>
                                        )}


                                        <div className='d-flex justify-content-end pb-4'
                                             style={{position: 'absolute', top: 0, right: 0}}>
                                            <HelpButtonComponent tab={'factors'}/>
                                            <ShareBlockOffCanvasComponent statement={baseQuestion.canonical_text}
                                                                          sharedTab={'factors'}
                                                                          correlations={[]} regressionResult={[]}/>

                                        </div>
                                    </div>
                                </div>

                            )}
                        </Tab.Content>
                    </Tab>
                </Tabs>
                <StudyInfoComponent data={studyInfo} requestedIndex={requestedIndex}
                                    isLoading={isLoadingStudyDetails}
                                    show={showStudyDetails}
                                    handleClose={handleClose} correlationDetails={correlationDetails}/>
            </div>


        </div>
    )
}

export default withRouter(Question);
