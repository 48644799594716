// todo: automate this!
import {AccountTypeParams} from "../types/userContext.type";

const colors: string[] = ['#004e6e', '#0085ba', '#009fc8', '#5dbcdf', '#8ad1e5'];
const backgrounds: string[] = ['#004e6e50', '#0085ba50', '#009fc850', '#5dbcdf50', '#8ad1e550'];
const suggestionsTimeout: number = 0;
const chartPrecision: number = 1000;
const searchExamples: string[] = ['Anxiety', 'I like to tidy up', 'How old are you?', 'Giant robots are cool', 'Narcissism', 'I smile a lot', 'Introversion', 'Cats are clearly better than dogs',]
const initialRegressionExamples: string[] = []
const includeTypes = ["likert scale", "yes or no", "any real number", "non-negative number", "any integer"];
const charLimit = 2;
const trialAccessLimit = 2;
const trialResultsNum = 4;
const cytoscapeLayoutSet = {
    'network': ['grid', 'circle', 'klay', 'cola', 'dagre', 'elk_box', 'elk_disco', 'elk_force', 'elk_layered', 'elk_mrtree'],
    'connections': ['preset', 'fcose', 'cose-bilkent', 'cola', 'dagre', 'elk_box', 'elk_disco', 'elk_force', 'elk_mrtree', 'elk_stress']
}

const accountTypeParams: AccountTypeParams = {
    'free': {"include_unsafe": false, "return_categories": true},
    'trial': {"include_unsafe": true, "return_categories": true},
    'paid': {"include_unsafe": true, "return_categories": true},
    'team': {"include_unsafe": true, "return_categories": true},
};

const factors = {
    'big5': {
        "openness": "openness (Big 5 personality composite score)",
        "conscientiousness": "conscientiousness (Big 5 personality composite score)",
        "extraversion": "extraversion (Big 5 personality composite score)",
        "agreeableness": "agreeableness (Big 5 personality composite score)",
        "emotional stability": "emotional stability (Big 5 personality composite score)",
    },
    'general': {
        "age": "age",
        "female": "female",
        "education": "What is your highest level of completed education?",
        "political conservatism": "On the political spectrum I am 'right' (conservative) rather than 'left' (progressive)",
        "social class": "Based on your background, the people you spend the most time with, and your current situation in life, to what extent would you consider yourself upper class rather than lower class?"
    },
    'subscales': {
        'adverse childhood experiences': 'adverse childhood experiences (ACEs composite score)',
        'attention-deficit / hyperactivity disorder': 'attention-deficit / hyperactivity disorder (ASRS ADHD composite score)',
        'avoidance behavior': 'avoidance behavior (composite score)',
        'generalized anxiety': 'generalized anxiety (GAD-7 composite score)',
        'joint hypermobility': 'joint hypermobility (composite score)',
        'negative self-talk': 'negative self-talk (composite score)',
        'depression': 'depression (PHQ-9 composite score)',
        'self-compassion': 'self-compassion (personality composite score)',
        'number of acutely stressful life events in the past two months, such as eviction, death or job loss': 'number of acutely stressful life events in the past two months, such as eviction, death or job loss (composite score)',
        'number of chronically stressful ongoing life events, such as financial strain, disability, or an abusive relationship': 'number of chronically stressful ongoing life events, such as financial strain, disability, or an abusive relationship (composite score)',
        'agreeableness': 'agreeableness (Big 5 personality composite score)',
        'closedness': 'closedness (Big 5 personality composite score)',
        'conscientiousness': 'conscientiousness (Big 5 personality composite score)',
        'disagreeableness': 'disagreeableness (Big 5 personality composite score)',
        'good employee': 'good employee (self-report composite score)',
        'extraversion': 'extraversion (Big 5 personality composite score)',
        'honesty': 'honesty (personality composite score)',
        'introversion': 'introversion (Big 5 personality composite score)',
        'neuroticism': 'neuroticism (Big 5 personality composite score)',
        'openness': 'openness (Big 5 personality composite score)',
        'emotional stability': 'emotional stability (Big 5 personality composite score)',
        'unconscientiousness': 'unconscientiousness (Big 5 personality composite score)',
        'anxiety': 'anxiety (personality composite score)',
        'attention-seeking': 'attention-seeking (personality composite score)',
        'authoritarianism': 'authoritarianism (personality composite score)',
        'charisma': 'charisma (personality composite score)',
        'compassion': 'compassion (personality composite score)',
        'creativity': 'creativity (personality composite score)',
        'emotional expressiveness': 'emotional expressiveness (personality composite score)',
        'fairness': 'fairness (personality composite score)',
        'psychological flexibility': 'psychological flexibility (personality composite score)',
        'gentleness': 'gentleness (personality composite score)',
        'greed avoidance': 'greed avoidance (personality composite score)',
        'humor': 'humor (personality composite score)',
        'industriousness': 'industriousness (personality composite score)',
        'intellect': 'intellect (personality composite score)',
        'introspection': 'introspection (personality composite score)',
        'irritability': 'irritability (personality composite score)',
        'orderliness': 'orderliness (personality composite score)',
        'perfectionism': 'perfectionism (personality composite score)',
        'sincerity': 'sincerity (personality composite score)',
        'sociability': 'sociability (personality composite score)',
        'trust': 'trust (personality composite score)',
        'unconventionality': 'unconventionality (personality composite score)',
        'well-being': 'well-being (personality composite score)',
        'conformity': 'conformity (personality composite score)',
        'forgiveness': 'forgiveness (personality composite score)',
        'manipulativeness': 'manipulativeness (personality composite score)',
        'optimism': 'optimism (personality composite score)',
        'prudence': 'prudence (personality composite score)',
        'disagreeblenss': 'disagreeblenss (Big 5 personality composite score)',
        'adaptability': 'adaptability (personality composite score)',
        'art appreciation': 'art appreciation (personality composite score)',
        'conservatism': 'conservatism (personality composite score)',
        'easy goingness': 'easy goingness (personality composite score)',
        'emotionality': 'emotionality (personality composite score)',
        'impulsivity': 'impulsivity (personality composite score)',
        'industry': 'industry (personality composite score)',
        'order': 'order (personality composite score)',
        'self-control': 'self-control (personality composite score)',
        'sensation-seeking': 'sensation-seeking (personality composite score)',
        'disagreebleness': 'disagreebleness (Big 5 personality composite score)',
        'Enneagram type 1 - the Reformer': 'Enneagram type 1 - the Reformer (personality composite score)',
        'Enneagram type 2 - the Helper': 'Enneagram type 2 - the Helper (personality composite score)',
        'Enneagram type 3 - the Achiever': 'Enneagram type 3 - the Achiever (personality composite score)',
        'Enneagram type 4 - the Individualist': 'Enneagram type 4 - the Individualist (personality composite score)',
        'Enneagram type 5 - the Investigator': 'Enneagram type 5 - the Investigator (personality composite score)',
        'Enneagram type 6 - the Loyalist': 'Enneagram type 6 - the Loyalist (personality composite score)',
        'Enneagram type 7 - the Enthusiast': 'Enneagram type 7 - the Enthusiast (personality composite score)',
        'Enneagram type 8 - the Challenger': 'Enneagram type 8 - the Challenger (personality composite score)',
        'Enneagram type 9 - the Peacemaker': 'Enneagram type 9 - the Peacemaker (personality composite score)',
        'extraversion (Myers-Briggs/MBTI-style)': 'extraversion (Myers-Briggs/MBTI-style personality composite score)',
        'introversion (Myers-Briggs/MBTI-style)': 'introversion (Myers-Briggs/MBTI-style personality composite score)',
        'judging (Myers-Briggs/MBTI-style)': 'judging (Myers-Briggs/MBTI-style personality composite score)',
        'perceiving (Myers-Briggs/MBTI-style)': 'perceiving (Myers-Briggs/MBTI-style personality composite score)',
        'sensing (Myers-Briggs/MBTI-style)': 'sensing (Myers-Briggs/MBTI-style personality composite score)',
        'intuition (Myers-Briggs/MBTI-style)': 'intuition (Myers-Briggs/MBTI-style personality composite score)',
        'thinking (Myers-Briggs/MBTI-style)': 'thinking (Myers-Briggs/MBTI-style personality composite score)',
        'feeling (Myers-Briggs/MBTI-style)': 'feeling (Myers-Briggs/MBTI-style personality composite score)',
        'altruistic': 'altruistic (personality composite score)',
        'anxious': 'anxious (personality composite score)',
        'comfortable with myself': 'comfortable with myself (personality composite score)',
        'conflict-avoidant': 'conflict-avoidant (personality composite score)',
        'conformist': 'conformist (personality composite score)',
        'conversationalist': 'conversationalist (personality composite score)',
        'demanding': 'demanding (personality composite score)',
        'emotional': 'emotional (personality composite score)',
        'empathetic': 'empathetic (personality composite score)',
        'exploratory': 'exploratory (personality composite score)',
        'group-oriented': 'group-oriented (personality composite score)',
        'hard-working': 'hard-working (personality composite score)',
        'intellectual': 'intellectual (personality composite score)',
        'leader-like': 'leader-like (personality composite score)',
        'logical': 'logical (personality composite score)',
        'organized': 'organized (personality composite score)',
        'perfectionistic': 'perfectionistic (personality composite score)',
        'planning': 'planning (personality composite score)',
        'pragmatic': 'pragmatic (personality composite score)',
        'quick-minded': 'quick-minded (personality composite score)',
        'rule abiding': 'rule abiding (personality composite score)',
        'socially energized': 'socially energized (personality composite score)',
        'spontaneous': 'spontaneous (personality composite score)'
    }
}
const numberTopSubscales = 5;


const big5Strings: string[] = []

const questionTabs = ['statistics',
    'mostCorrelated', 'predictCorrelation',
    'predictLinearRegression', 'network', 'factors', 'connections']

type shareTextDetails = {
    [tabType: string]: {
        titleM: string;
        shareText: (stmt: string) => string;
    };
};

const shareTextDetails: shareTextDetails = {
    'statistics': {
        titleM: "PersonalityMap.io Statistics for the statement",
        shareText: (stmt: string) => `PersonalityMap.io Statistics for the statement: ${stmt}`
    },
    'mostCorrelated': {
        titleM: "PersonalityMap.io predicted correlations for the statement",
        shareText: (stmt: string) => `PersonalityMap.io predicted correlations for the statement: ${stmt}`
    },
    'predictCorrelation': {
        titleM: "PersonalityMap.io correlations for the statement",
        shareText: (stmt: string) => `PersonalityMap.io correlations for the statement: ${stmt}`
    },
    'predictLinearRegression': {
        titleM: "PersonalityMap.io Predicted linear regression for the statement",
        shareText: (stmt: string) => `PersonalityMap.io Predicted linear regression for the statement: ${stmt}`
    },
    'network': {
        titleM: 'PersonalityMap.io Network of connections for the statement',
        shareText: (stmt: string) => `PersonalityMap.io Network of connections for the statement: ${stmt}`
    },
    'connections': {
        titleM: 'PersonalityMap.io Non-trivial connections for the statement',
        shareText: (stmt: string) => `PersonalityMap.io Non-trivial connections for the statement: ${stmt}`
    },
    'factors': {
        titleM: 'PersonalityMap.io Predicted partial correlation with each of the personality traits for the statement',
        shareText: (stmt: string) => `PersonalityMap.io Predicted partial correlation with each of the personality traits for the statement: ${stmt}`
    },
    'default': {
        titleM: 'PersonalityMap.io Predicted correlations for the statement',
        shareText: (stmt: string) => `PersonalityMap.io Predicted correlations for the statement: ${stmt}`
    },
};

const UiConfig = {
    colors,
    backgrounds,
    suggestionsTimeout,
    chartPrecision,
    searchExamples,
    includeTypes,
    charLimit,
    trialAccessLimit,
    trialResultsNum,
    initialRegressionExamples,
    big5Strings,
    questionTabs,
    shareTextDetails,
    factors, numberTopSubscales, accountTypeParams, cytoscapeLayoutSet
}

export default UiConfig;
