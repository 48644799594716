import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import StudyDataService from '../services/study.service';
import StudyType from "../types/study.type";
import StudyQuestionType from "../types/study-question.type";
import ReactGA from "react-ga4";

export interface CorrelationDetails {
    correlation: number,
    study_id: string,
    sample_size: number,
}

export function useStudyDetails(qStudyQuestions?: StudyQuestionType[] | null) {
    const [studyInfo, setStudyInfo] = useState<StudyType[] | undefined>();
    const [requestedIndex, setRequestedIndex] = useState<number | undefined>();
    const [showStudyDetails, setShowStudyDetails] = useState(false);
    const [correlationDetails, setCorrelationDetails] = useState<CorrelationDetails | undefined>();

    const handleClose = () => {
        setRequestedIndex(undefined);
        setShowStudyDetails(false)
        setCorrelationDetails(undefined);
    };

    function handleStudyDetailsResults(res: any) {
        if (res.studies) {
            setStudyInfo(res.studies);
        } else {
        }
    }

    const handleShowStudyDetails = (index: number | undefined, correlationItem: any) => {
        ReactGA.send({
            hitType: 'event',
            eventCategory: '...',
            eventAction: 'User clicked Study Info button'
        });


        if (index !== undefined) setRequestedIndex(index);
        if (studyInfo && requestedIndex === undefined && correlationItem) {
            let idx = studyInfo.findIndex((s: StudyType) => s.id === correlationItem.study_id);
            if (idx !== -1) {
                setRequestedIndex(idx);
                setCorrelationDetails(correlationItem);
            } else {
                console.error('Study not found for correlation item:', correlationItem);
            }
        }

        setShowStudyDetails(true);
    };

    const {isFetching: isLoadingStudyDetails, refetch: goGetStudyDatails} = useQuery<StudyQuestionType[], Error>(
        "query-study-details",
        async () => {
            let org_id = 'main';
            if (qStudyQuestions && qStudyQuestions.length > 0) {
                let ids = qStudyQuestions.map(s => s.study_id);
                return await StudyDataService.getStudiesByIds(org_id, ids);
            } else if (qStudyQuestions && qStudyQuestions.length === 0) {
                return await StudyDataService.getAllStudies(org_id);
            }
            throw new Error('No study questions provided');
        },
        {
            enabled: false,
            retry: 3,
            onSuccess: (res) => handleStudyDetailsResults(res),
            onError: (err: any) => console.log(err)
        }
    );

    useEffect(() => {
    }, [requestedIndex]);

    useEffect(() => {
        if (qStudyQuestions && !studyInfo) {
            goGetStudyDatails().then(() => {
            }).catch(err => console.error('Failed to fetch study details:', err));
        }
    }, [qStudyQuestions]);

    return {
        studyInfo,
        isLoadingStudyDetails,
        requestedIndex,
        correlationDetails,
        showStudyDetails,
        handleClose,
        handleShowStudyDetails
    };
}